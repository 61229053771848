import {PageLayout} from "@components";
import {JoinInstitutionsV2} from "../../landingpagev2/_components/_JoinInstitutionsV2/_JoinInstitutionsV2";
import { PartnersV2 } from "@components/LimsSoftware/PartnersV2";
import React from "react";
import FeatureCard, {FeatureCardLayout, FeatureCardProps} from "@components/FeatureCard/FeatureCard";
import {StaticImage} from "gatsby-plugin-image";
import * as styles from "../../products/orders/orders.module.scss";
import {Demo} from ".././biopharmaceutical";
import Margins from "@components/Margins/Margins";
import classNames from "classnames";
import { LogosAndG2Section } from "@components/Solutions/LogosAndG2Section";

export default function Repository () {
    const demoProps = {
        title: "Enhance your biorepository R&D process with Genemod",
        subtitle: "Advance biorepository research and development using our advanced lab automation software.",
        srcImg: "person-operates-equipment.png",
        disableBackgroundShapes: true,
        rightSectionStylesOverride: styles.rightSectionStylesOverride,
		getADemoOverride: classNames(styles.getADemoOverride, styles.clinicalGetADemoOverride),
        titleStylesOverride: styles.clinicalDemoTitleStylesOverride,
		subtitleStylesOverride: styles.clinicalDemoSubtitleStylesOverride,
		leftSectionOverride: styles.clinicalDemoLeftSectionOverride
    }

    const cards: FeatureCardProps[] = [
        {
            title: "Create a digital overview of your biorepository research environment",
            list: [
                "Enhance every stage of biorepository management, ensuring efficient sample storage and retrieval.",
                "Adopt Genemod to transform your biorepository practices, setting a new standard in sample preservation and access.",
                "Accelerate processes in biorepository for more efficient sample handling and research facilitation.",
            ],
            image: (
                <StaticImage
                    src={"../../../assets/solutions/freezers-advanced-search.png"}
                    alt="Easily Manage and Submit Orders"
                />
            ),
            className: styles.featureQuickAccess,
			listItemWeight: "SEMIBOLD",
			titleStylesOverride: styles.featureTitleStylesOverride,
        },
        {
            title: "Efficient location and management of all consumables in a unified system",
            list: [
                "Genemod’s Laboratory Information Management System serves as a comprehensive platform, centralizing research data, sample tracing, test results, quality control measures, and analysis.",
                "Efficiently document and organize materials with virtual freezers, and share information with colleagues to decrease time on secondary tasks.",
                "Optimize your lab organization and consumable management with a solution that precisely tracks and records essential details of all lab resources.",
            ],
            image: (
                <StaticImage
                    src={"../../../assets/products/single-source-of-truth.png"}
                    alt="Single Source of truth "
                />
            ),
            orientation: "right",
			className: styles.featureQuickAccess,
			listItemWeight: "SEMIBOLD",
			titleStylesOverride: styles.featureTitleStylesOverride,
        },
    ];

    const footerContent = {
        headerText: "Powered by Genemod",
        subheaderText: "Genemod enables large institutions with multiple teams to centralize projects and experiments on one platform. Google Docs isn’t built for life science R&D - so we built a platform that is.",
    }

    return (
        <PageLayout
            seoOptions={{ type: "PREDEFINED", pageName: "repository" }}
        >
            <Margins className={styles.demoMargins}>
            <Demo {...demoProps}/>            
            </Margins>
            <Margins className={styles.featureMargins}>
				<FeatureCardLayout className={styles.featuresLayout}>
					{cards.map((card, index) => {
						return <FeatureCard {...card} key={index.toString()} />;
					})}
				</FeatureCardLayout>
			</Margins>
           <LogosAndG2Section />
			<Margins className={styles.institutionsMargins}>
				<JoinInstitutionsV2
					{...footerContent}
					outerContainerClassName={
						styles.institutionsOuterContainerClassName
					}
					useHeaderStylesV4={true}
					useSubheaderStylesV4={true}
					joinInstitutionsContainerOverride={
						styles.joinInstitutionsContainerOverride
					}
				/>
			</Margins>
        </PageLayout>
    );
}